import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../Link';

import logoBlack from '../../../../images/logoBlack.svg';
import logoWhite from '../../../../images/logoWhite.svg';

const Logo = ({ inBlack }) => (
    <Link to="/">
        <img width={263} height={43} src={inBlack ? logoBlack : logoWhite} alt="App4Event Logo" role="presentation" />
    </Link>
);

Logo.propTypes = {
    inBlack: PropTypes.bool,
};

Logo.defaultProps = {
    inBlack: false,
};

export default Logo;
