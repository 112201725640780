export const wrapper = ({ theme, backgroundColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    position: 'relative',
    zIndex: 99,

    width: '100%',
    padding: '1.25rem 4rem',

    max768px: {
        padding: '1.25rem 3rem',
    },

    max576px: {
        padding: '0.75rem 1rem',
    },

    min576px: {
        max768px: {
            padding: '1.25rem 3rem 1.25rem 0',
        },
    },

    transition: 'background-color 0.3s ease',

    backgroundColor,
    ...(backgroundColor === theme.colors.greyLight && {
        boxShadow: '0px 2px 6px 0 rgba(0,0,0,0.15)',
    }),

    max992px: {
        display: 'block',
    },

    min1490px: {
        padding: '1.25rem 6rem',
    },
});
