export default () => ({
    top: 0,
    position: 'fixed',

    zIndex: 999,

    boxSizing: 'border-box',
    width: '100%',

    '> div': {
        position: 'absolute',
        width: '100%',
    },
});
