import React from 'react';
import { connect as connectFela, FelaComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { combineRules } from 'fela';

import languages from '../../i18n/languages';
import getLocalizedPath from '../../i18n/getLocalizedPath';

import { PageContext } from '../../pageContext';

import * as componentStyles from './LanguageSwitcher.style';

import { Dropdown, Menu, Icon } from 'antd';

const flags = {
    cs: 'CZ',
    en: 'EN',
};

const items = (className, originalPath) => (
    <Menu>
        {languages.map(lang => (
            <Menu.Item key={lang.locale} className={className}>
                <Link to={getLocalizedPath(originalPath, lang.locale)}>{flags[lang.locale]}</Link>
            </Menu.Item>
        ))}
    </Menu>
);

const LanguageSwitcher = ({ styles, customStyle, inBlack }) => (
    <FelaComponent style={combineRules(componentStyles.wrapper, customStyle)} inBlack={inBlack}>
        <PageContext.Consumer>
            {({ locale, originalPath }) => (
                <Dropdown
                    overlayClassName={styles.overlay}
                    overlay={items(styles.link, originalPath)}
                    trigger={['click']}
                >
                    <button type="button" className="ant-dropdown-link">
                        {flags[locale]} <Icon type="down" />
                    </button>
                </Dropdown>
            )}
        </PageContext.Consumer>
    </FelaComponent>
);

LanguageSwitcher.propTypes = {
    inBlack: PropTypes.bool,
    customStyle: PropTypes.func,
};

LanguageSwitcher.defaultProps = {
    inBlack: false,
    customStyle: () => {},
};

export default connectFela(componentStyles)(LanguageSwitcher);
