import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import debounce from 'lodash.debounce';

import style from './HeaderScroller.style';
import Header from './Header';

// From jQuery, returns NaN when reach top
const getWindowOffset = () => {
    return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
};

class HeaderScroller extends React.Component {
    static propTypes = {
        links: PropTypes.array.isRequired,
        inBlackStyle: PropTypes.bool,
        hasHeader: PropTypes.bool,
    };
    static defaultProps = {
        inBlackStyle: false,
        hasHeader: false,
    };

    static DEBOUNCE_TIME = 1000 / 60;

    constructor(props) {
        super(props);
        this.state = {
            sticky: true, // For not JS enabled devices or crawlers
            headerHeight: null,
        };

        this.handleScrollUnbounced = this.handleScrollUnbounced.bind(this);
        this.handleResizeUnbounced = this.handleResizeUnbounced.bind(this);

        this.ref = React.createRef();

        this.handleScroll = debounce(this.handleScrollUnbounced, Header.DEBOUNCE_TIME);
        this.handleResize = debounce(this.handleResizeUnbounced, Header.DEBOUNCE_TIME);
    }

    componentDidMount() {
        this.handleResizeUnbounced();
        this.handleScrollUnbounced();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScrollUnbounced = () => {
        const headerHeight = this.props.hasHeader
            ? window.innerHeight - this.state.headerHeight
            : this.state.headerHeight;
        this.setState({
            sticky: getWindowOffset() > headerHeight,
        });
    };

    handleResizeUnbounced = () => {
        this.setState({
            headerHeight: this.ref.current.clientHeight,
        });
    };

    render() {
        const { sticky } = this.state;
        const { inBlackStyle, links, withLanguageSwitcher } = this.props;

        return (
            <FelaComponent style={style}>
                <div ref={this.ref}>
                    <Header
                        inBlackStyle={inBlackStyle}
                        withBackground={sticky}
                        links={links}
                        withLanguageSwitcher={withLanguageSwitcher !== false}
                    />
                </div>
            </FelaComponent>
        );
    }
}

export default HeaderScroller;
