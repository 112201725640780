export const wrapper = ({ theme, inBlack }) => ({
    marginLeft: '0.75rem',
    letterSpacing: 0.88,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'inline',
    textAlign: 'center',
    position: 'relative',
    fontFamily: theme.fontFamily,

    '& .ant-dropdown-link': {
        fontFamily: theme.fontFamily,
        fontWeight: 600,
        border: 'none',
        background: 'none',
        color: 'white',
        '& i': {
            paddingLeft: '0.25rem',
            color: theme.colors.green,
        },
    },
});

const linkDefault = color => ({
    display: 'block',
    fontWeight: 600,
    textDecoration: 'none',
    color,
});

export const link = ({ theme }) => ({
    textAlign: 'center',
    fontFamily: theme.fontFamily,
    '& a': {
        padding: '0.25rem 0',
        ...linkDefault(theme.colors.black),
        textDecoration: 'none',
    },
    '&:hover': {
        backgroundColor: theme.colors.green,
        '& a': {
            color: theme.colors.white,
        },
    },
});

export const overlay = ({ theme }) => ({
    paddingTop: '0.2rem',
    '& ul': {
        padding: 0,
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.colors.white,
    },
});
