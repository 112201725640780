import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import * as componentStyles from './Header.style';

import theme from '../../../styles/theme';
import Navbar from './Navbar/Navbar';

const getBackgroundColor = (hasBackground, inBlack) => {
    const { colors } = theme;
    if (!hasBackground) {
        return colors.transparent;
    }
    if (inBlack) {
        return colors.greyLight;
    }
    return colors.navBlack;
};

const Header = ({ withBackground, links, inBlackStyle, withLanguageSwitcher }) => {
    const backgroundColor = getBackgroundColor(withBackground, inBlackStyle);
    return (
        <FelaComponent backgroundColor={backgroundColor} style={componentStyles.wrapper} as="header">
            <Navbar links={links} inBlack={inBlackStyle} withLanguageSwitcher={withLanguageSwitcher} />
        </FelaComponent>
    );
};

Header.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            labelId: PropTypes.string.isRequired,
            asButton: PropTypes.bool,
        }),
    ),
    inBlackStyle: PropTypes.bool,
};

Header.defaultProps = {
    links: [],
    inBlackStyle: false,
};

export default Header;
