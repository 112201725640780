import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../../../Link';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from '../../../LanguageSwitcher/LanguageSwitcher';
import * as componentStyles from './Navbar.style';
import { FelaComponent } from 'react-fela';
import theme from '../../../../styles/theme';
import Hamburger from './Hamburger';
import Logo from './Logo';
import { LinkStyle } from '../../../Link/Link.style';

const Navbar = ({ links, inBlack, withLanguageSwitcher }) => {
    const [isOpened, setOpened] = useState(false);

    const handleToggle = useCallback(() => {
        setOpened(!isOpened);
    }, [isOpened, setOpened]);

    // Auto close
    useEffect(() => {
        const func = () => {
            if (isOpened && window.innerWidth > 991) {
                handleToggle();
            }
        };
        window.addEventListener('resize', func);
        return () => window.removeEventListener('resize', func);
    }, [handleToggle, isOpened]);

    return (
        <>
            <FelaComponent style={componentStyles.wrapper}>
                <Logo inBlack={inBlack && !isOpened} />
                <FelaComponent
                    as={'nav'}
                    style={componentStyles.innerWrapper}
                    color={inBlack && !isOpened ? theme.colors.black : theme.colors.white}
                >
                    {links.map(({ to, labelId, type, asButton }, i) => (
                        <Link key={i} type={type} styleType={asButton ? LinkStyle.BUTTON : LinkStyle.LINK} to={to}>
                            <FormattedMessage key={i} id={labelId} />
                        </Link>
                    ))}
                    <Hamburger isActive={isOpened} onClick={handleToggle} />
                    {withLanguageSwitcher && (
                        <LanguageSwitcher customStyle={componentStyles.languageSwitcher} inBlack={inBlack} />
                    )}
                </FelaComponent>
            </FelaComponent>
            <FelaComponent style={componentStyles.dropdown} isOpened={isOpened}>
                {links.map(({ to, labelId, type }, i) => (
                    <Link key={i} type={type} to={to} onClick={handleToggle}>
                        <FormattedMessage key={i} id={labelId} />
                    </Link>
                ))}
                {withLanguageSwitcher && (
                    <LanguageSwitcher customStyle={componentStyles.languageSwitcherMobile} inBlack={inBlack} />
                )}
            </FelaComponent>
        </>
    );
};

Navbar.propTypes = {
    inBlack: PropTypes.bool.isRequired,
    withLanguageSwitcher: PropTypes.bool.isRequired,
};

export default Navbar;
