import React from 'react';
import * as PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import style, { LinkStyle, LinkType } from './Link.style';

import LocalizedLink from '../LocalizedLink';

const Link = ({ type, styleType, to, position, onClick, forceLocale, ...props }) => (
    <FelaComponent type={type} styleType={styleType} style={style} position={position}>
        {({ className }) =>
            type === LinkType.GATSBY ? (
                <LocalizedLink
                    to={to}
                    forceLocale={forceLocale}
                    {...props}
                    className={`Link Link-${type} Link-${styleType} ${props.className} ${className}`}
                    onClick={onClick}
                    activeClassName={`Link-active`}
                />
            ) : (
                <a
                    href={to}
                    {...props}
                    className={`Link Link-${type} Link-${styleType} ${props.className} ${className}`}
                    onClick={onClick}
                >
                    {props.children}
                </a>
            )
        }
    </FelaComponent>
);

Link.propTypes = {
    type: PropTypes.oneOf(Object.values(LinkType)),
    styleType: PropTypes.oneOf(Object.values(LinkStyle)),
    className: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
    onClick: PropTypes.func,
    forceLocale: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Link.defaultProps = {
    type: LinkType.GATSBY,
    styleType: LinkStyle.LINK,
    className: '',
    position: undefined,
    onClick: () => {},
    forceLocale: false,
};

export default Link;
