const linkActiveStyle = {
    paddingBottom: 0,
    textDecoration: 'none',
};

export const wrapper = ({ theme }) => ({
    position: 'relative',
    zIndex: 99,
    width: '100%',
    '& .Link': {
        border: 'none',
        textDecoration: 'none',
    },
    '& .Link.Link-link': {
        transition: 'color 0.3s ease',
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.green,
        },
        '&.Link-active': {
            color: theme.colors.green,
        },
    },
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
        maxWidth: 200,
        min480px: {
            maxWidth: 230,
        },
        min576px: {
            maxWidth: 263,
        },
    },
});

export const innerWrapper = ({ theme, color }) => ({
    float: 'right',

    '& .Link-link': {
        paddingBottom: 0,
        ':hover': linkActiveStyle,
        ':focus': linkActiveStyle,
        ':active': linkActiveStyle,
        max992px: {
            display: 'none',
        },
        fontWeight: 600,
    },

    '& .Link-button': {
        fontWeight: 600,
    },

    '& .Link': {
        '&.Link-link': {
            color,
        },
        display: 'inline-block',
        marginRight: '1.5rem',
        border: 'none',
        min992px: {
            ':last-of-type': {
                marginRight: 0,
            },
        },
        fontSize: '1.05rem',
        max480px: {
            display: 'none',
        },
    },
    '& nav': {
        display: 'none',
        min576px: {
            display: 'block',
        },
    },

    '& nav .Link': {
        textDecoration: 'none',
    },
});

export const languageSwitcherMobile = ({ theme }) => ({
    overflow: 'auto',
    clear: 'both',
    fontSize: '2rem',
    margin: '0 auto',
    display: 'block',
    '& svg': {
        width: '2em',
        height: '2em',
    },
});

export const languageSwitcher = ({ theme, inBlack }) => ({
    marginTop: '1rem',
    max992px: {
        display: 'none',
    },
    ...(inBlack && {
        '& .ant-dropdown-link': {
            color: theme.colors.black,
        },
    }),
});

const hideEffect = (theme, isOpened) => ({
    border: 'none',
    textAlign: 'center',
    width: '100%',
    display: 'block',
    float: 'left',
    paddingBottom: '2rem',
    color: theme.colors.green,
    fontSize: isOpened ? '2rem' : 0,
    transition: 'font-size 0.35s ease',
});

export const dropdown = ({ theme, isOpened }) => ({
    position: 'fixed',
    display: 'block',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.greyDarkest,
    overflow: 'hidden',
    ...((isOpened && {
        height: '100vh',
        padding: '10rem 4rem 2.25rem',
    }) || {
        height: 0,
        padding: '0 4rem 0',
    }),
    transition: 'all 0.5s ease',
    width: '100%',
    left: 0,
    top: 0,
    margin: 0,
    color: 'black',
    '& .Link': {
        ...hideEffect(theme, isOpened),
    },
    '& button': {
        ...hideEffect(theme, isOpened),
    },
});
