export const hamburger = ({ theme }) => ({
    display: 'inline-block',
    float: 'right',
    cursor: 'pointer',
    transitionProperty: 'opacity, filter',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'linear',
    font: 'inherit',
    color: 'inherit',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
    overflow: 'visible',
    '&:hover': {
        opacity: '0.7',
    },
    height: 40,
    min992px: {
        display: 'none',
    },
});

export const hamburgerBox = ({ theme }) => ({
    width: 24,
    perspective: 80,
    height: 40,
    display: 'inline-block',
    position: 'relative',
});

const hamInner = color => ({
    width: 24,
    height: 3,
    backgroundColor: color,
    borderRadius: 3,
    position: 'absolute',
    transitionProperty: 'transform',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
});

export const hamburgerInner = ({ theme, isActive }) => ({
    display: 'block',
    marginTop: -2,
    top: '50%',
    transition:
        'transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',
    ...hamInner(theme.colors.green),
    ...(isActive && {
        backgroundColor: 'transparent',
        transform: 'rotateY(180deg)',
    }),
    '&:before': {
        ...hamInner(theme.colors.green),
        transition: 'transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',
        content: '""',
        display: 'block',
        top: -7,
        ...(isActive && {
            transform: 'translate3d(0, 7px, 0) rotate(45deg)',
        }),
    },
    '&:after': {
        ...hamInner(theme.colors.green),
        transition: 'transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',
        content: '""',
        display: 'block',
        bottom: -7,
        ...(isActive && {
            transform: 'translate3d(0, -7px, 0) rotate(-45deg)',
        }),
    },
});
