import React from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela, FelaComponent } from 'react-fela';
import * as style from './Hamburger.style';

const Hamburger = ({ styles, isActive, onClick }) => (
    <button className={styles.hamburger} type="button" onClick={onClick}>
        <span className={styles.hamburgerBox}>
            <FelaComponent style={style.hamburgerInner} as={'span'} isActive={isActive} />
        </span>
    </button>
);

Hamburger.propTypes = {
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    styles: PropTypes.shape({
        hamburger: PropTypes.string,
        hamburgerBox: PropTypes.string,
        hamburgerInner: PropTypes.string,
    }).isRequired,
};

export default connectFela(style)(Hamburger);
